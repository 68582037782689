<template>
  <div class="chart-wrap">
    <div ref="SCEchart" class="sceChart"></div>
  </div>
</template>

<script>
import Chart from 'echarts'
export default {
    name:'chartShow',
    data(){
        return{
        }
    },
    mounted(){
        this.drawLine();
    },
    methods:{
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            let myChart = Chart.init(this.$refs.SCEchart);
            // 绘制图表
            myChart.setOption({
                //配置X轴
                xAxis: {
                    //坐标轴类型
                    type:'category',
                    //配置类目名称。 可以逐一设置，具体看文档
                    data: ['12.05', '12.06', '12.07', '12.08', '12.09', '12.10','12.11','12.12','12.13','12.14','12.15','12.16'],
                    //配置 鼠标移入时显示的 阴影指示器'line' 直线指示器 'shadow' 阴影指示,器  'none' 无指示器
                    axisPointer: {
                        show:true, //show 必须写，开关的作用
                        type: 'shadow'
                    }
                },
                //配置Y轴
                yAxis: [
                    {
                        type: 'value', //坐标轴类型
                        name: '', //名称
                        min: 0, //最小值
                        max: 1600, //最大值
                        interval: 200, //列数
                        axisLabel: {  //坐标轴刻度标签的相关设置。
                            formatter: '{value}'  //字符串模板
                        }
                    },
                ],
                //系列列表。每个系列通过 type 决定自己的图表类型,一个对象表示一个列表
                series: [
                    {
                        name: '',
                        type: 'bar',
                        barWidth:'40%',
                        data: [0, 0, 0, 0, 0, 0,0,0,0,0,0,0],
                        itemStyle:{ //图形的颜色。默认指向全局的option.color
                            color:'#6699ff'
                        }
                    },
                ],
            });
            //鼠标事件
            myChart.on('click',function(params){
                console.log(params)
            })
        },


       
    }
}
</script>
<style lang="scss">
.chart-wrap {
  height: 420px;
}
.sceChart {
  height: 420px;
}
</style>