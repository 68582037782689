<template>
  <div class="content-wrap index-content-wrap">
     <bread-menu title="首页">
     </bread-menu>
    <div class="chart-area">
      <div class="chart-top">
        <ul class="top-list">
          <li>
            <h5 class="up">0</h5>
            <p>总收益 (元)</p>
          </li>
          <li>
            <h5 class="up">0</h5>
            <p>今日收益(元)</p>
          </li>
          <li class="down">
            <h5 class="down">0</h5>
            <p>昨日收益(元)</p>
          </li>
        </ul>
      </div>
      <chartShow />
    </div>
    <div class="fn-show">
      <div class="fn-list class-list">
        <div class="icon"></div>
        <div class="list-title">
          <h5>{{userData.catalogsNum}}</h5>
          <p>课程管理</p>
        </div>
      </div>
      <div class="fn-list work-list">
        <div class="icon"></div>
        <div class="list-title">
          <h5>{{userData.worksNum}}</h5>
          <p>作品管理</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartShow  from "@/components/chartShow.vue";
import breadMenu from "@/components/breadMenu.vue";
export default {
  name:'home',
  components:{
    chartShow,
    breadMenu
  },
  data(){
    return{
      userData:{
        catalogsNum:0,
        worksNum:0
      },
      params: {
        token: "",
        userId: "",
      },
    }
  },
  created(){
    this.params.token = window.localStorage.getItem("token");
    this.params.userId = window.localStorage.getItem("userId");
    this.getUserData();
  },
  methods:{
    getUserData() {
      this.$axios({
        method: "post",
        params: this.params,
        url: "/teacher/index/userData",
      }).then((res) => {
        if (res.data.code !== "0000") {
          return this.$message.error(res.data.message);
        }
       this.userData=res.data.data;
      });
    },
  }
};
</script>

<style>
</style>